import React, { useState, useCallback } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { toNumber } from 'lodash'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default function GalleryImages () {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(
            filter: { relativePath: { regex: "/gallery-images/" }, name: {} }
            sort: { order: DESC, fields: modifiedTime }
          ) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    srcSetWebp
                    srcWebp
                    presentationHeight
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <Gallery
            photos={data.allFile.edges.map(
              x =>
                [
                  {
                    src: x.node.childImageSharp.fluid.srcWebp,
                    width: toNumber(
                      x.node.childImageSharp.fluid.presentationWidth
                    ),
                    height: toNumber(
                      x.node.childImageSharp.fluid.presentationHeight
                    )
                  }
                ][0]
            )}
            onClick={openLightbox}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={data.allFile.edges
                    .map(
                      x =>
                        [
                          {
                            src: x.node.childImageSharp.fluid.srcWebp,
                            width: toNumber(
                              x.node.childImageSharp.fluid.presentationWidth
                            ),
                            height: toNumber(
                              x.node.childImageSharp.fluid.presentationHeight
                            )
                          }
                        ][0]
                    )
                    .map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      )}
    ></StaticQuery>
  )
}
