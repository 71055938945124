import React, { Component } from 'react'
import GalleryImages from '../components/galleryimages'

class PhotoGallery extends Component {
 
  render () {
    return (
      <div>
        <GalleryImages></GalleryImages>
      </div>
    )
  }
}
export default PhotoGallery
