import React from 'react'
import Layout from '../components/layout'
import Gallery from '../components/gallery'

const PhotosPage = () => (
  
  <Layout fullwidth={true}>
<Gallery/>

  </Layout>
)

export default PhotosPage
